import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { AlertService } from "./alert.service";
import { CustomHttpParamEncoder } from "./encode-service";
import { EncryptService } from "./encrypt.service";
import { NetworkService } from "./network.service";

@Injectable({
  providedIn: "root",
})
export class ApiCallService {
  constructor(
    private http: HttpClient,
    private es: EncryptService,
    private router: Router,
    private alert: AlertService
  ) { }

  postApiCallAuth(url: string, body: object) {
    let obj = this.es.dataIn(body);
    return this.http
      .post(url, obj, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
          }
        }),
        catchError((err) => {
          if ((err.status == 422)) {
            let result = this.es.unmaskErrData(err.error)
            this.alert.fireToastF(result.message[0])
            if (err.statusText == "Forbidden") {
              this.alert.fireToastF(err.error.message);
            }
            let badData: any = this.es.decrypt(err.error);
            this.alert.fireToastF(badData.message);
          }
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }

          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  putApiCallAuth(url: string, body: object) {
    let obj = this.es.dataInParam(body)
    // console.log(obj);
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('input', obj);
      
    return this.http
      .put(url, params, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
          }
        }),
        catchError((err) => {
          if ((err.status == 422)) {
            let result = this.es.unmaskErrData(err.error)
            this.alert.fireToastF(result.message[0])
            if (err.statusText == "Forbidden") {
              this.alert.fireToastF(err.error.message);
            }
            let badData: any = this.es.decrypt(err.error);
            this.alert.fireToastF(badData.message);
          }
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }

          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }


  putApiCallAuthNoData(url: string) {
    return this.http
      .put(url, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
          }
        }),
        catchError((err) => {
          if ((err.status == 422)) {
            let result = this.es.unmaskErrData(err.error)
            this.alert.fireToastF(result.message[0])
            if (err.statusText == "Forbidden") {
              this.alert.fireToastF(err.error.message);
            }
            let badData: any = this.es.decrypt(err.error);
            this.alert.fireToastF(badData.message);
          }
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }

          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  deleteApiCallAuth(url: string) {
    return this.http
      .delete(url, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
          }
        }),
        catchError((err) => {
          if ((err.status == 422)) {
            let result = this.es.unmaskErrData(err.error)
            this.alert.fireToastF(result.message[0])
            if (err.statusText == "Forbidden") {
              this.alert.fireToastF(err.error.message);
            }
            let badData: any = this.es.decrypt(err.error);
            this.alert.fireToastF(badData.message);
          }
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }

          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  postApiCallNoAuth(url: string, body: object) {
    let obj = this.es.dataIn(body);
    return this.http
      .post(url, obj, {
        headers: NetworkService.getHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
            return result;
          }
        }),
        catchError((err: any) => {
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }
          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  getApiCallAuth(url: string): any {
    return this.http
      .get(url, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
          }
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }
          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  optionApiCallAuth(url: any): any {

    return this.http
      .options(url, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
          }
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }
          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  optionApiCallAuthParams(url: string, body: any): any {
    let obj = this.es.dataInParam(body)
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('input', obj);
    return this.http
      .options(url, {
        params,
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
          }
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }
          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  getApiCallAuthParam(url: string, body: any): any {
    let obj = this.es.dataInParam(body)
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('input', obj);
    return this.http
      .get(url, { params, headers: NetworkService.getAuthHeader() })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
          }
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.alert.fireToastF("Unauthenticated");
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          } else if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          } else {
            let data: any = this.es.unmaskData(err.error);
            this.alert.fireToastF(data.message[0]);
          }
          return "";
        })
      );
  }

  getApiCallNoAuth(url: string): any {
    return this.http
      .get(url, {
        headers: NetworkService.getHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          if (result.status == true) {
            return result;
          } else {
            this.alert.fireToastF(result.response.message[0]);
            return result;
          }
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }
          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  postApiCallAuthNE(url: string, body: object) {
    return this.http
      .post(url, body, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          return r;
          // if (r.status == true) {
          //   return r;
          // } else {
          //   this.alert.fireToastF(r.response.message[0]);
          // }
        }),
        catchError((err) => {
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }
  postApiCallAuthNEE(url: string, body: object) {
    return this.http
      .post(url, body, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          let result: any = this.es.unmaskData(r);
          return result;
          // if (r.status == true) {
          //   return r;
          // } else {
          //   this.alert.fireToastF(r.response.message[0]);
          // }
        }),
        catchError((err) => {
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }

  getApiCallAuthNE(url: string): any {
    return this.http
      .get(url, {
        headers: NetworkService.getAuthHeader(),
      })
      .pipe(
        map((r: any) => {
          return r;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate(["/auth/login"]);
            localStorage.clear();
            sessionStorage.clear();
          }
          if (err.status == 500) {
            this.alert.fireToastF("Something went wrong");
          }
          let data: any = this.es.unmaskData(err.error);
          this.alert.fireToastF(data.message[0]);
          return "";
        })
      );
  }
}
