<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu" *ngIf="!menuLoading"  style="background-color:#ff006b">
    <!-- Left Menu Start -->
    <!-- id="side-menu" #sideMenu -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems; index as i">
        <li class="menu-title font-size-15" *ngIf="item.isTitle">
          {{ item.label }}
        </li>
        <li
          *ngIf="
            !item.isTitle &&
            !item.isLayout &&
            (item.role == role ||
              item.role2 == role ||
              item.role3 == role ||
              item.role4 == role ||
              item.role5 == role ||
              item.role6 == role ||
              item.role7 == role)
          "
          [ngClass]="{ 'mm-active': activePrimaryMenu === item.label }"
        >
          <a
            *ngIf="hasItems(item)"
            class="is-parent d-flex align-items-center cursor-pointer"
            [ngClass]="{ 'parent-active': activePrimaryMenu === item.label }"
            (click)="sideBarColl(i, item)"
          >
            <!-- [ngClass]="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }" -->
            <!-- <i class="bx {{ item.icon }} " *ngIf="item.icon"></i> 2-->
            <span> {{ item.label }} </span>

            <i
              class="bx bx-chevron-down dropdown-icon mt-1"
              [ngClass]="{
                'bx-chevron-down': !isColl(i),
                'bx-chevron-right': isColl(i)
              }"
            ></i>

            <!-- <span *ngIf="item.badge || pendingCount !== 0 || pendingCount !== undefined" class="badge rounded-pill float-end bg-{{ item.badge.variant }}">{{ pendingCount }}</span> -->
            <!-- <span
              class="badge rounded-pill float-end bg-{{ item.badge.variant }}"
              *ngIf="item.badge"
              >{{ item.badge.text }}</span
            > -->
          </a>
          <a
            [routerLink]="item.link"
            *ngIf="!hasItems(item)"
            class="side-nav-link-ref"
            routerLinkActive="active"
            [ngClass]="{ active: activePrimaryMenu === item.label }"
          >
            <!-- <i class="{{ item.icon }}" *ngIf="item.icon"></i> 1-->
            <span> {{ item.label }} </span>
            <!-- <span
              *ngIf="item.badge"
              class="badge rounded-pill bg-{{ item.badge.variant }} float-end"
              >{{ pendingCount }}</span
            >
            <span
              class="badge rounded-pill bg-{{ item.badge.variant }} float-end"
              *ngIf="item.badge"
              >{{ item.badge.text }}</span
            > -->
          </a>
          <ul
            *ngIf="hasItems(item)"
            class="sub-menu"
            aria-expanded="true"
            [ngStyle]="{ display: isColl(i) ? 'none' : 'block' }"
          >
            <li *ngFor="let subitem of item.subItems">
              <a
                [routerLink]="subitem.link"
                *ngIf="!hasItems(subitem)"
                class="side-nav-link-ref"
                [attr.data-parent]="subitem.parentId"
                routerLinkActive="active"
                [ngClass]="{
                  active: activeSecondryMenu === subitem.label,
                  hide:
                    subitem.srole == role ||
                    subitem.srole2 == role ||
                    subitem.srole3 == role ||
                    subitem.srole4 == role ||
                    subitem.srole5 == role ||
                    subitem.srole6 == role ||
                    subitem.srole7 == role
                }"
              >
                <!-- <i class="bx {{ subitem.icon }}" *ngIf="subitem.icon"></i> 3-->
                <span> {{ subitem.label }} </span>
                <!-- {{ subitem.label }} -->
              </a>
              <a
                *ngIf="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId"
              >
                {{ subitem.label + "kkkk" }}
              </a>
              <ul
                *ngIf="hasItems(subitem)"
                class="sub-menu mm-collapse"
                aria-expanded="false"
              >
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a
                    [attr.data-parent]="subSubitem.parentId"
                    [routerLink]="subSubitem.link"
                    routerLinkActive="active"
                    class="side-nav-link-ref"`
                  >
                    {{ subSubitem.label }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
      <ng-container>
        <li (click)="logout()" class="ms-4 ps-1">
          <a class="logout">
            <!-- <i class="mc-logout"></i> -->
            <span> Logout</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>

<!-- Sidebar -->

<div class="vertical-menu bg-{{ role }}">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
