import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Consts } from "../utils/consts";

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  constructor() { }

  static server_url(): string {
    return Consts.URL();
  }

  static server_v(): string {
    return "/api/admin";
  }

  static getAuthHeader(): HttpHeaders {
    // let header = new HttpHeaders({
    //   Accept: 'application/json',
    //   Authorization: 'Bearer ' + this.authToken(),
    // }).set('Content-Type', 'application/json')
    //   .set('Access-Control-Allow-Origin', '*');
    // return header;

    let header = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + this.authToken(),
    });
    return header;
  }

  static getHeader(): HttpHeaders {
    let header = new HttpHeaders()
    // .set('Content-Type', 'application/json')
    // .set('Access-Control-Allow-Origin', '*');
    return header;
  }

  static authToken(): any {
    return sessionStorage.getItem("accessToken") || undefined;
  }

  // Authentication

  static login(): string {
    return this.server_url() + this.server_v() + "/login";
  }
  static register(): string {
    return this.server_url() + this.server_v() + "/register";
  }
  static verifyOTP(): string {
    return this.server_url() + this.server_v() + "/update-password";
  }
  static resendOTP(): string {
    return this.server_url() + this.server_v() + "/resend-otp";
  }
  static logout(): string {
    return this.server_url() + this.server_v() + "/logout";
  }
  static forgetPassowrd(): string {
    return this.server_url() + this.server_v() + "/forget-password";
  }
  static updatePassword(): string {
    return this.server_url() + this.server_v() + "/change-password";
  }

  //Users

  static profileUpdate(): string {
    return this.server_url() + this.server_v() + "/user/profile-updated";
  }
  static changePassword(): string {
    return this.server_url() + this.server_v() + "/change_password";
  }

  //userManagement

  static usersList(): string {
    return this.server_url() + this.server_v() + "/mc/user";
  }

  static getCuttingAndRetailer(): string {
    return this.server_url() + this.server_v() + "/mc/user/cutting-center-and-retailer-list";
  }

  static getAdminAndFranchise(): string {
    return this.server_url() + this.server_v() + "/mc/user/admin-and-franchise-list";
  }


  static userView(id: any): string {
    return this.server_url() + this.server_v() + "/mc/user/" + id + "/edit";
  }

  // Vendor Management
  static vendorsList(): string {
    return this.server_url() + this.server_v() + "/supplier/list";
  }
  static vendorView(): string {
    return this.server_url() + this.server_v() + "/supplier/view";
  }
  static vendorAdd(): string {
    return this.server_url() + this.server_v() + "/supplier/store";
  }
  static changeVendorStatus(): string {
    return this.server_url() + this.server_v() + "/supplier/change-status";
  }
  static getSupplierOptions(type: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/suppliers/" + type;
  }

  //Common
  static upload(): string {
    return this.server_url() + this.server_v() + "/file/upload";
  }

  //Products & Cats
  static addProduct(): string {
    return this.server_url() + this.server_v() + "/mc/product";
  }
  static getProducts(): string {
    return this.server_url() + this.server_v() + "/mc/product";
  }
  static getProductOptions(type: any): string {
    return this.server_url() + this.server_v() + "/mc/products/" + type;
  }


  static viewProducts(id): string {
    return this.server_url() + this.server_v() + "/mc/product/" + id + "/edit";
  }


  static updateProduct(data: any): string {
    return this.server_url() + this.server_v() + "/mc/product/" + data;
  }


  static deleteProduct(data: any): string {
    return this.server_url() + this.server_v() + "/mc/product/" + data;
  }


  static productChangeStatus(id: any): string {
    return this.server_url() + this.server_v() + "/mc/product/" + id + '/on-hold'
  }

  static categoryChangeStatus(id: any): string {
    return (
      this.server_url() + this.server_v() + "/mc/product-category/" + id + '/on-hold'
    );
  }

  static getCategoryOptions(): string {
    return this.server_url() + this.server_v() + "/mc/product-categories";
  }

  static getUnit(): string {
    return this.server_url() + this.server_v() + "/mc/units";
  }

  static productPriceChange(): string {
    return this.server_url() + this.server_v() + "/price/store";
  }

  static getRawProductList(): string {
    return this.server_url() + this.server_v() + "/product/get-raw-products";
  }


  static addCategory(): string {
    return this.server_url() + this.server_v() + "/mc/product-category";
  }

  static updateCategory(data: any): string {
    return this.server_url() + this.server_v() + "/mc/product-category/" + data;
  }

  static getCategories(): string {
    return this.server_url() + this.server_v() + "/mc/product-category";
  }

  static deleteCategory(data: any): string {
    return this.server_url() + this.server_v() + "/mc/product-category/" + data;
  }

  static editCategory(data: any): string {
    return this.server_url() + this.server_v() + "/mc/product-category/" + data + "/edit";
  }


  // User Management

  static addUser(): string {
    return this.server_url() + this.server_v() + "/mc/user";
  }

  static updateUser(id: any): string {
    return this.server_url() + this.server_v() + "/mc/user/" + id;
  }

  static changeUserStatus(id: any): string {
    return this.server_url() + this.server_v() + "/mc/user/" + id + '/on-hold';
  }

  static deleteUser(id: any): string {
    return this.server_url() + this.server_v() + "/mc/user/" + id;
  }


  static getUserOptions(): string {
    return this.server_url() + this.server_v() + "/user/get-users";
  }
  static getBranchOptions(): string {
    return this.server_url() + this.server_v() + "/branch/list";
  }
  static roleList(): string {
    return this.server_url() + this.server_v() + "/mc/roles";
  }

  static getCustomerList(): string {
    return this.server_url() + this.server_v() + "/customer/list";
  }

  static getAdminList(data: any): string {
    return this.server_url() + this.server_v() + "/mc/user/admins/" + data;
  }

  // order

  static storePosCustomer(): string {
    return this.server_url() + this.server_v() + "/mc/order/pos";
  }


  static getPosProductList(): string {
    return this.server_url() + this.server_v() + "/mc/pos-products";
  }


  static getOrderList(): string {
    return this.server_url() + this.server_v() + "/mc/order";
  }

  static getOrderView(id: any) {
    return this.server_url() + this.server_v() + "/mc/order/" + id;
  }

  // static orderAssign() {
  //   return this.server_url() + this.server_v() + "/order/assign";
  // }

  static ReadyForDelivery() {
    return this.server_url() + this.server_v() + "/order/delivery";
  }

  static orderReceived() {
    return this.server_url() + this.server_v() + "/order/received";
  }

  static getDeliveryBoyList() {
    return this.server_url() + this.server_v() + "/mc/user/delivery-boy-list";
  }

  static deliveryBoyAssign(id: any) {
    return this.server_url() + this.server_v() + "/mc/assign-delivery-boy/" + id;
  }

  // static orderReadyForDelivery() {
  //   return this.server_url() + this.server_v() + "/order/delivery";
  // }

  // static orderReceived() {
  //   return this.server_url() + this.server_v() + "/order/received";
  // }

  static getInvoice(data: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/mc/order/" +
      data +
      "/invoice"
    );
  }

  // bulk order

  static getBulkOrderList() {
    return this.server_url() + this.server_v() + "/mc/bulk-order";
  }

  static getBulkOrderView() {
    return this.server_url() + this.server_v() + "/bulkOrder/view";
  }

  static getBulkOrderSupplierList() {
    return this.server_url() + this.server_v() + "/bulkOrder/supplier-list";
  }

  static BulkOrderAssign(id: any) {
    return this.server_url() + this.server_v() + "/mc/bulk-order/" + id + '/assigned-branch';
  }

  static makeBulkOrderCompleted(id: any) {
    return this.server_url() + this.server_v() + "/mc/bulk-order/" + id + '/make-order-completed';
  }

  static BulkOrderDelivery() {
    return this.server_url() + this.server_v() + "/bulkOrder/delivery";
  }

  static BulkOrderReceived() {
    return this.server_url() + this.server_v() + "/bulkOrder/received";
  }
  // States List
  // static stateList(): string {
  //   return this.server_url() + "/api/v1.0/state/list";
  // }
  // static citiesList(): string {
  //   return this.server_url() + "/api/v1.0/city/list";
  // }

  //Branch
  static branchList(): string {
    return this.server_url() + this.server_v() + "/branch/list";
  }
  static addBranch(): string {
    return this.server_url() + this.server_v() + "/branch/store";
  }
  static changeBranchState(): string {
    return this.server_url() + this.server_v() + "/branch/status/update";
  }

  //Profile
  static getProfile(): string {
    return this.server_url() + this.server_v() + "/user/profile";
  }
  static updateProfile(): string {
    return this.server_url() + this.server_v() + "/profile/update";
  }

  //investment
  static addInvestment(): string {
    return this.server_url() + this.server_v() + "/investment/store";
  }
  static listInvestment(): string {
    return this.server_url() + this.server_v() + "/investment/list";
  }
  static investmentDetails(): string {
    return this.server_url() + this.server_v() + "/investment/detail";
  }
  static terminate(): string {
    return this.server_url() + this.server_v() + "/investment/terminate";
  }
  static reinvest(): string {
    return this.server_url() + this.server_v() + "/investment/reinvest";
  }
  static getAgreement(): string {
    return this.server_url() + this.server_v() + "/pdf/generate";
  }

  //requests

  static getRequests(): string {
    return this.server_url() + this.server_v() + "/approval/list";
  }
  static requestStatusUpdate(): string {
    return this.server_url() + this.server_v() + "/approval/status/update";
  }

  //get Vendors

  static getVendors(): string {
    return this.server_url() + this.server_v() + "/vendor/list";
  }
  static viewVendor(): string {
    return this.server_url() + this.server_v() + "/vendor/detail";
  }

  //Export data
  static exportUser(): string {
    return this.server_url() + this.server_v() + "/users/export";
  }
  static exportVendor(): string {
    return this.server_url() + this.server_v() + "/vendors/export";
  }
  static exportInvestment(): string {
    return this.server_url() + this.server_v() + "/investments/export";
  }

  //notifications
  static getNotificationsList(): string {
    return this.server_url() + this.server_v() + "/notification/list";
  }

  static upcommingList(): string {
    return this.server_url() + this.server_v() + "/investment/upcoming/list";
  }

  static getDashboard(): string {
    return this.server_url() + this.server_v() + "/dashboard";
  }

  //payment records
  static getPayments(): string {
    return this.server_url() + this.server_v() + "/investment/dues/list";
  }
  static madePayments(): string {
    return this.server_url() + this.server_v() + "/investment/dues/paid";
  }

  // Purchase Orders
  static purchaseOrdersList(): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order";
  }

  static addPurchaseOrder(): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order";
  }

  static purchaseOrderView(id: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + id;
  }

  static editPurchaseOrder(id: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + id + '/edit';
  }

  static updatePurchaseOrder(id: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + id;
  }

  static deletePurchaseOrder(id: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + id;
  }

  static statusChangePurchaseOrder(id: any, status: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + id + '/status-update/' + status;
  }

  static makePurchasePayment(id: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/make-a-purchase-payment/" + id
  }

  static purchaseItemStore(id: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + id + '/item';
  }

  static purchaseItemEdit(orderId: any, itemId: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + orderId + '/item/' + itemId;
  }

  static purchaseItemUpdate(orderId: any, itemId: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + orderId + '/item/' + itemId;
  }

  static purchaseItemDelete(orderId: any, itemId: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + orderId + '/item/' + itemId;
  }

  static getPurchaseOrderNumber(type: any, module: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/purchase-order-numbers/" + type + '/' + module
  }

  static getPurchaseOrderNumberProduct(id: any): string {
    return this.server_url() + this.server_v() + "/mc/purchase-order/" + id + '/products'
  }



  static assignSupplier(): string {
    return this.server_url() + this.server_v() + "/purchase-order/assign";
  }
  static purchaseOrderDelivery(): string {
    return this.server_url() + this.server_v() + "/purchase-order/delivery";
  }
  static orderReceivedStatus(): string {
    return (
      this.server_url() + this.server_v() + "/purchase-order/order-received"
    );
  }
  static purchaseOrderDeliveryStatus(): string {
    return (
      this.server_url() + this.server_v() + "/purchase-order/product-received"
    );
  }

  static exportPurchaseInvoice(id: any): string {
    return (
      this.server_url() + this.server_v() + "/mc/purchase-order/" + id + '/invoice'
    );
  }

  // Stocks
  static stocksList(): string {
    return this.server_url() + this.server_v() + "/mc/stock-management";
  }
  static addStock(): string {
    return this.server_url() + this.server_v() + "/stock/store";
  }

  static stockView(id): string {
    return this.server_url() + this.server_v() + "/mc/stock-management/products/" + id;
  }

  static stockProductView(userId: any, productId: any): string {
    return this.server_url() + this.server_v() + "/mc/stock-management/product/stock/" + userId + '/' + productId;
  }

  static feedView(): string {
    return this.server_url() + this.server_v() + "/feed-stock/view";
  }

  static feedStock(): string {
    return this.server_url() + this.server_v() + "/feed-stock/update";
  }
  // price

  static priceList(): string {
    return this.server_url() + this.server_v() + "/mc/price-management";
  }
  static addPrice(id: any): string {
    return this.server_url() + this.server_v() + "/mc/price-management/" + id;
  }

  static priceView(id: any): string {
    return this.server_url() + this.server_v() + "/mc/price-management/" + id;
  }

  static feedPriceView(): string {
    return this.server_url() + this.server_v() + "/feed-price/view";
  }

  static feedPriceUpdate(): string {
    return this.server_url() + this.server_v() + "/feed-price/update";
  }

  // Feeds
  static getFeeds(): string {
    return this.server_url() + this.server_v() + "/feed/get-feeds";
  }

  static addFeed(): string {
    return this.server_url() + this.server_v() + "/feed/store";
  }
  static viewFeed(): string {
    return this.server_url() + this.server_v() + "/feed/view";
  }
  static changeFeedStatus(): string {
    return this.server_url() + this.server_v() + "/feed/change-status";
  }
  static getFeedList(): string {
    return this.server_url() + this.server_v() + "/feed/list";
  }

  // feed Purchase

  static storeFeedPurchase(): string {
    return this.server_url() + this.server_v() + "/feed-purchase/store";
  }

  static FeedPurchaseList(): string {
    return this.server_url() + this.server_v() + "/feed-purchase/list";
  }

  static FeedPurchaseView(): string {
    return this.server_url() + this.server_v() + "/feed-purchase/view";
  }

  static FeedPurchaseAssign(): string {
    return this.server_url() + this.server_v() + "/feed-purchase/assign";
  }

  static FeedPurchaseDelivery(): string {
    return this.server_url() + this.server_v() + "/feed-purchase/delivery";
  }

  static FeedPurchaseFeedReceived(): string {
    return this.server_url() + this.server_v() + "/feed-purchase/feed-received";
  }

  static FeedPurchaseOrderReceived(): string {
    return (
      this.server_url() + this.server_v() + "/feed-purchase/order-received"
    );
  }

  // city management

  static getStateList(): string {
    return this.server_url() + this.server_v() + "/mc/states";
  }

  static getDistrictList(id: any): string {
    return this.server_url() + this.server_v() + "/mc/districts/" + id;
  }

  static getCityManagementList(): string {
    return this.server_url() + this.server_v() + "/city-list";
  }


  static getCityList(): string {
    return this.server_url() + this.server_v() + "/city/list";
  }

  static cityStatusChange(): string {
    return this.server_url() + this.server_v() + "/city/change-status";
  }

  // brand

  static getBrandList(): string {
    return this.server_url() + this.server_v() + "/mc/brand";
  }

  static getBrandOption(): string {
    return this.server_url() + this.server_v() + "/mc/brands";
  }

  static editBrand(id: any): string {
    return this.server_url() + this.server_v() + "/mc/brand/" + id + "/edit"
  }

  static StoreBrand(): string {
    return this.server_url() + this.server_v() + "/mc/brand";
  }

  static updateBrand(id: any): string {
    return this.server_url() + this.server_v() + "/mc/brand/" + id;
  }

  static deleteBrand(id: any): string {
    return this.server_url() + this.server_v() + "/mc/brand/" + id;
  }

  static viewBrand(): string {
    return this.server_url() + this.server_v() + "/brand/view";
  }

  static changeBrandStatus(id: any): string {
    return this.server_url() + this.server_v() + "/mc/brand/" + id + "/on-hold";
  }

  // Banner

  static getBannerList(): string {
    return this.server_url() + this.server_v() + "/banner/list";
  }

  static StoreBanner(): string {
    return this.server_url() + this.server_v() + "/banner/store";
  }

  static viewBanner(): string {
    return this.server_url() + this.server_v() + "/banner/view";
  }

  static changeBannerStatus(): string {
    return this.server_url() + this.server_v() + "/banner/change-status";
  }

  // report


  static purchaseReport(): string {
    return this.server_url() + this.server_v() + "/report/purchase-report";
  }

  static purchaseReportShow(data: any): string {
    return (
      this.server_url() + this.server_v() + "/report/purchase-report/" + data
    );
  }

  static purchaseReportExport(data: any, type: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/purchase-report/" +
      data +
      "/export/"
      + type
    );
  }


  static purchaseReporTypeExport(data: any, params: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/mc/purchase-order/report/export/" +
      data + '?' + params
    );
  }

  // sales

  static salesReport(): string {
    return this.server_url() + this.server_v() + "/mc/order/report";
  }

  static salesReportShow(data: any): string {
    return this.server_url() + this.server_v() + "/report/sale-report/" + data;
  }

  static salesReportExport(data: any, type: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/sale-report/" +
      data +
      "/export/"
      + type
    );
  }


  static salesReportTypeExport(data: any, params: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/mc/order/report/export/" +
      data + '?' + params
    );
  }

  // supplier

  static supplierReport(): string {
    return this.server_url() + this.server_v() + "/report/supplier-report";
  }

  static supplierReportShow(data: any): string {
    return (
      this.server_url() + this.server_v() + "/report/supplier-report/" + data
    );
  }

  static supplierReportExport(data: any, type: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/supplier-report/" +
      data +
      "/export/"
      + type
    );
  }

  static supplierReportTypeExport(data: any, params: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/supplier-report/export/" +
      data + '?input=' + params
    );
  }

  // user

  static userReport(): string {
    return (
      this.server_url() + this.server_v() + "/report/user-management-report"
    );
  }

  static userReportShow(data: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/user-management-report/" +
      data
    );
  }

  static userReportExport(data: any, type: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/user-management-report/" +
      data +
      "/export/"
      + type
    );
  }

  static userReportTypeExport(data: any, params: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/mc/user/report/export/" +
      data + '?' + params
    );
  }

  // price

  static priceReport(): string {
    return this.server_url() + this.server_v() + "/report/price-report";
  }

  static priceReportShow(data: any): string {
    return this.server_url() + this.server_v() + "/report/price-report/" + data;
  }

  static priceReportExport(data: any, type: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/price-report/" +
      data +
      "/export/"
      + type
    );
  }

  // Bulk order

  static bulkReport(): string {
    return this.server_url() + this.server_v() + "/mc/bulk-order/report";
  }

  static bulkReportShow(data: any): string {
    return (
      this.server_url() + this.server_v() + "/report/bulk-order-report/" + data
    );
  }

  static bulkReportExport(data: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/bulk-order-report/" +
      data +
      "/export"
    );
  }


  static bulkReportTypeExport(type: any, params: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/mc/bulk-order/report/export/" +
      type + '?' + params
    );
  }

  // customer

  static customerReport(): string {
    return (
      this.server_url() + this.server_v() + "/mc/customer/report"
    );
  }

  static customerReportShow(data: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/customer-management-report/" +
      data
    );
  }

  static customerReportExport(data: any, type: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/customer-management-report/" +
      data +
      "/export/" + type
    );
  }


  static customerReportTypeExport(type: any, params: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/mc/customer/report/export/" +
      type + '?' + params
    );

    // return (
    //   this.server_url() +
    //   this.server_v() +
    //   "/mc/user/report/export/" +
    //   data + '?' + params
    // );
  }

  // feed report

  static feedReport(): string {
    return (
      this.server_url() + this.server_v() + "/report/feed-purchase-report"
    );
  }

  static feedReportShow(data: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/feed-purchase-report/" +
      data
    );
  }

  static feedReportExport(data: any, type: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/feed-purchase-report/" +
      data +
      "/export/" + type
    );
  }


  static feedReportTypeExport(data: any, params: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/mc/purchase-order/report/export/" +
      data + '?' + params
    );
  }


  // delivery report

  static deliveryReport(): string {
    return (
      this.server_url() + this.server_v() + "/report/delivery-boy-report"
    );
  }

  static deliveryReportShow(data: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/delivery-boy-report/" +
      data
    );
  }

  static deliveryReportExport(data: any, type: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/delivery-boy-report/" +
      data +
      "/export/" + type
    );
  }


  static deliveryReportTypeExport(data: any, params: any): string {
    return (
      this.server_url() +
      this.server_v() +
      "/report/delivery-boy-report/export/" +
      data + '?' + params
    );
  }



  // status

  static purchaseStatusList(): string {
    return this.server_url() + this.server_v() + "/purchase-order/status-list";
  }

  static salesStatusList(): string {
    return this.server_url() + this.server_v() + "/sales-order/status-list";
  }

  static bulkStatusList(): string {
    return this.server_url() + this.server_v() + "/bulk-order/status-list";
  }

  // dashboard

  static dashboard(): string {
    return this.server_url() + this.server_v() + "/dashboard";
  }

  // offer

  static getOffers(): string {
    return this.server_url() + this.server_v() + "/offer/list";
  }

  static offerView(): string {
    return this.server_url() + this.server_v() + "/offer/view";
  }

  static offerStatusChange(): string {
    return this.server_url() + this.server_v() + "/offer/change-status";
  }

  static offerStore(): string {
    return this.server_url() + this.server_v() + "/offer/store";
  }

  static notificationList(): string {
    return this.server_url() + this.server_v() + "/notification/my-notifications";
  }

  static openNotification(data: any): string {
    return this.server_url() + this.server_v() + "/notification/update/" + data;
  }


  // delivery boy
  static deliveryBoyList(): string {
    return this.server_url() + this.server_v() + "/delivery-boy-payment-collect-history"
  }


  static deliveryBoyUpdate(data: any): string {
    return this.server_url() + this.server_v() + "/delivery-boy-payment-collect-history/" + data + "/update";
  }


  static deliveryBoyApprovalList(): string {
    return this.server_url() + this.server_v() + "/delivery-boy"
  }

  static deliveryBoyApprovalAction(id: any, action: any): string {
    return this.server_url() + this.server_v() + "/delivery-boy/" + id + "/action/" + action;
  }


  // monster Foods

  static becomeAFranchies(): string {
    return this.server_url() + this.server_v() + "/become-a-franchies"
  }

  static contactUs(): string {
    return this.server_url() + this.server_v() + "/contact-us"
  }


  // mortality

  static getMortality() {
    return this.server_url() + this.server_v() + "/mc/mortality"
  }

  static updateMortality(id: any) {
    return this.server_url() + this.server_v() + "/mc/mortality/" + id
  }

  // weightloss

  static getWeightloss() {
    return this.server_url() + this.server_v() + "/mc/weight-loss-convertion"
  }

  static updateWeightloss(id: any) {
    return this.server_url() + this.server_v() + "/mc/weight-loss-convertion/" + id
  }


  // Product Return

  static getProductReturn() {
    return this.server_url() + this.server_v() + "/mc/product-return"
  }

  static updateProductReturn(id: any) {
    return this.server_url() + this.server_v() + "/mc/product-return/" + id
  }

  // blog 

  static getBlog() {
    return this.server_url() + this.server_v() + "/mc/post"
  }

  static storeBlog() {
    return this.server_url() + this.server_v() + "/mc/post";
  }

  static showBlog(id: any) {
    return this.server_url() + this.server_v() + "/mc/post/" + id;
  }


  // static showBlog(id: any) {
  //   return this.server_url() + this.server_v() + "/mc/post/" + id + '/edit';
  // }

  static updateBlog(id: any) {
    return this.server_url() + this.server_v() + "/mc/post/" + id;
  }

  static onHoldBlog(id: any) {
    return this.server_url() + this.server_v() + "/mc/post/" + id + '/on-hold';
  }

  static deleteBlog(id: any) {
    return this.server_url() + this.server_v() + "/mc/post/" + id;
  }


  // district 

  static createDistrict() {
    return this.server_url() + this.server_v() + "/mc/district";
  }

  static updateDistrict(id: any) {
    return this.server_url() + this.server_v() + "/mc/district/" + id;
  }

}
