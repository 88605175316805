<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box bg-g">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img
              src="../../../assets/images/monster_food_logo.svg"
              alt=""
              height="32"
            />
          </span>
          <span class="logo-lg">
            <img
              src="../../../assets/images/monster_food_logo.svg"
              alt=""
              height="42"
            />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img
              src="../../../assets/images/monster_food_logo.svg"
              alt=""
              height="auto"
              width="46"
            />
          </span>
          <span class="logo-lg">
            <img
              src="../../../assets/images/monster-chicken-logo.png"
              alt=""
              height="42"
            />
          </span>
        </a>
      </div>

      <!-- <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button> -->
      <div class="d-flex align-items-center ms-3" *ngIf="!isLoading">
        <div *ngIf="userDetails.role[0].value == 'superAdmin'">
          <h5 class="mb-0 text-dark fw-bold">Hello, {{ userDetails.name }}</h5>
        </div>
        <div *ngIf="userDetails.role[0].value != 'superAdmin'">
          <h5 class="mb-0 text-dark fw-bold">
            Hello, {{ userDetails.name | titlecase }} -
            {{ userDetails.role[0].key }}
          </h5>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <!-- <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          ngbDropdownToggle
        >
          <i class="bx bx-customize"></i>
        </button>

        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end"
          ngbDropdownMenu
        >
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="Github" />
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img
                    src="assets/images/brands/bitbucket.png"
                    alt="bitbucket"
                  />
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble" />
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox" />
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img
                    src="assets/images/brands/mail_chimp.png"
                    alt="mail_chimp"
                  />
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack" />
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          (click)="getNotifications()"
          ngbDropdownToggle
        >
          <i class="bx bx-bell bx-dada"></i>
          <span
            class="badge notification-color-red rounded-pill"
            *ngIf="notificationList.length > 0"
            >{{ notCount }}</span
          >
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          ngbDropdownMenu
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notifications</h6>
              </div>
              <!-- <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{
                  "HEADER.NOTIFICATIONS.VIEW_ALL" | translate
                }}</a>
              </div> -->
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px">
            <a
              href="javascript: void(0);"
              class="text-reset notification-item"
              *ngFor="let item of notificationList; let i = index"
            >
              <div
                class="media"
                (click)="openNotification(item.notification_id)"
              >
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                    *ngIf="item.is_read === 0"
                  >
                    <i class="bx bxs-message"></i>
                  </span>
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                    *ngIf="item.is_read === 1"
                  >
                    <i class="bx bx-check-double"></i>
                  </span>
                </div>
                <div class="media-body">
                  <!-- <h6 class="mt-0 mb-1">
                    {{ "HEADER.NOTIFICATIONS.FIRST.TITLE" | translate }}
                  </h6> -->
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ item.notification }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ item.created_at.slice(0, 10) }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <div
              align="center"
              *ngIf="notificationList.length < totalNotifications"
            >
              <button
                (click)="loadMoreNotifications()"
                class="btn btn-success btn-sm btn-rounded my-4"
                id="btn-save-event"
              >
                Load More
              </button>
            </div>

            <!-- <app-empty [title]="''"></app-empty> -->
            <div
              class="account-pages my-5"
              *ngIf="notificationList.length == 0"
            >
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-8 col-xl-6">
                    <div>
                      <img
                        src="../../../assets/images/error-img.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-lg-12">
                    <div class="text-center mb-5">
                      <h6>No Notification Found</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ngx-simplebar>
          <!-- <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">{{
                "HEADER.NOTIFICATIONS.LOAD_MORE" | translate
              }}</span>
            </a>
          </div> -->
        </div>
      </div>

      <!-- <div
        class="dropdown d-inline-block"
        ngbDropdown
        *ngIf="role !== 'superAdmin'"
      >
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          ngbDropdownToggle
        >
          <i class="bx bx-bell"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          ngbDropdownMenu
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ "HEADER.NOTIFICATIONS.TITLE" | translate }}
                </h6>
              </div>
              <div class="col-auto">
                <a
                  href="javascript: void(0);"
                  routerLink="/dashboard"
                  class="small"
                  >View All</a
                >
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px">
            <a
              href="javascript: void(0);"
              class="text-reset notification-item"
              (click)="viewNotification(item)"
              *ngFor="let item of notificationList; let i = index"
            >
              <div class="media">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i
                      class="bx bx-money"
                      *ngIf="item.type == 'viewInvestment'"
                    ></i>
                    <i
                      class="bx bxs-time"
                      *ngIf="item.type == 'approvalList'"
                    ></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ item.title }}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ item.message }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> {{ item.createdAt }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <div align="center" *ngIf="notificationList.length == 0">
              <img
                class="empty-img"
                src="../../../../assets/images/error-img.png"
                alt=""
              />
              <h6 class="mt-5">No Notifications Found</h6>
            </div>
          </ngx-simplebar>
          <div
            class="p-2 border-top d-grid"
            *ngIf="totalNotifications > notificationList.length"
          >
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
              (click)="loadMoreNotifications()"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">Load More</span>
            </a>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          *ngIf="!isLoading"
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="../../../assets/images/topuser.png"
            alt="Header Avatar"
          />

          <!-- <span class="d-none d-xl-inline-block ms-1 me-2">{{
            name | titlecase
          }}</span> -->
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block me-2"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/profile"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i>
            Profile</a
          >
          <a
            class="dropdown-item"
            href="javascript: void(0);"
            (click)="changePasswordPop(cngPass)"
            ><i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            Change Password</a
          >
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | translate }}</a
          >
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="toggleRightSidebar()"
        >
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>

<ng-template #cngPass role="document" let-modal data-bs-backdrop="static">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Change Password</h5>

    <button
      type="button"
      class="btn-close"
      aria-hidden="true"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <form (ngSubmit)="changePassword()" [formGroup]="userForm">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="password">Old Password</label>
            <div class="input-group auth-pass-inputgroup">
              <input
                type="{{ togglePassword1 ? 'text' : 'password' }}"
                autocomplete="off"
                class="form-control"
                formControlName="oldPassword"
                placeholder="Enter old password"
                [ngClass]="{
                  'is-invalid': submitted && form.oldPassword.errors
                }"
                aria-label="oldPassword"
                aria-describedby="password-addon"
              />

              <button
                class="btn btn-light ms-0"
                type="button"
                (click)="showPassword1()"
                id="password-addon"
              >
                <i class="mdi mdi-eye-outline"></i>
              </button>
              <div
                *ngIf="submitted && form.oldPassword.errors"
                class="invalid-feedback"
              >
                <span *ngIf="form.oldPassword.errors.required"
                  >Password is required</span
                >
                <span
                  *ngIf="
                    !form.oldPassword.errors.required && form.oldPassword.errors
                  "
                  >Password should be minimum 8 characters</span
                >
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="password">New Password</label>
            <div class="input-group auth-pass-inputgroup">
              <input
                type="{{ togglePassword2 ? 'text' : 'password' }}"
                autocomplete="off"
                class="form-control"
                formControlName="password"
                placeholder="Enter password"
                [ngClass]="{
                  'is-invalid': submitted && form.password.errors
                }"
                aria-label="Password"
                aria-describedby="password-addon"
              />

              <button
                class="btn btn-light ms-0"
                type="button"
                (click)="showPassword2()"
                id="password-addon"
              >
                <i class="mdi mdi-eye-outline"></i>
              </button>
              <div
                *ngIf="submitted && form.password.errors"
                class="invalid-feedback"
              >
                <span *ngIf="form.password.errors.required"
                  >Password is required</span
                >
                <span
                  *ngIf="
                    !form.password.errors.required &&
                    form.password.errors &&
                    form.password.value !== form.oldPassword.value
                  "
                  >Password should be minimum 8 characters</span
                >

                <span
                  *ngIf="
                    form.password.value == form.oldPassword.value &&
                    form.password.errors &&
                    !form.password.errors.required
                  "
                  >New password must differ from old password</span
                >
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="password">Confirm Password</label>
            <div class="input-group auth-pass-inputgroup">
              <input
                type="{{ togglePassword ? 'text' : 'password' }}"
                autocomplete="off"
                class="form-control"
                formControlName="cPassword"
                placeholder="Enter confirm password"
                [ngClass]="{
                  'is-invalid': submitted && form.cPassword.errors
                }"
                aria-label="Password"
                aria-describedby="password-addon"
              />

              <button
                class="btn btn-light ms-0"
                type="button"
                (click)="showPassword()"
                id="password-addon"
              >
                <i class="mdi mdi-eye-outline"></i>
              </button>
              <div
                *ngIf="submitted && form.cPassword.errors"
                class="invalid-feedback"
              >
                <span *ngIf="form.cPassword.errors.required"
                  >Confirm Password is required</span
                >
                <span
                  *ngIf="
                    !form.cPassword.errors.required && form.cPassword.errors
                  "
                  >Confirm Password should match with new password</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-mred float-end" id="btn-save-event">
        Change Password
      </button>
    </form>
  </div>
</ng-template>
