<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <div class="ms-5 ">
          {{ year }} © Monster Foods.
          <b>version - 0.7</b>
        </div>
        
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">Developed by Cloudin</div>
      </div>
    </div>
  </div>
</footer>
