export class Consts {
  static Version = "0.0.1";
  static Production = false;
  static SERVER_URL_TEST = "https://api.monsterfoods.in";
  static SERVER_URL_PROD = "https://api.monsterfoods.in";

  static URL(): string {
    if (this.Production) return this.SERVER_URL_PROD;
    else return this.SERVER_URL_TEST;
  }
}
