import { Injectable } from "@angular/core";
import { ApiCallService } from "./apiCall.service";
import { NetworkService } from "./network.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private api: ApiCallService) { }

  getTodayDate(): string {
    let date = new Date();
    date.setUTCDate(date.getUTCDate());
    let d: string[] = date.toLocaleDateString().split("/").reverse();
    return d
      .concat(
        d
          .splice(1, 2)
          .reverse()
          .map((d) => (d.length == 1 ? "0" + d : d))
      )
      .join("-");
  }

  upload(data: any) {
    return this.api.postApiCallAuthNEE(NetworkService.upload(), data);
  }

  getRequests(data: any) {
    return this.api.postApiCallAuth(NetworkService.getRequests(), data);
  }

  updateRequests(data: any) {
    return this.api.postApiCallAuth(NetworkService.requestStatusUpdate(), data);
  }

  exportUser(data: any) {
    return this.api.postApiCallAuth(NetworkService.exportUser(), data);
  }
  exportVendor(data: any) {
    return this.api.postApiCallAuth(NetworkService.exportVendor(), data);
  }
  exportInvesment(data: any) {
    return this.api.postApiCallAuth(NetworkService.exportInvestment(), data);
  }

  // notifications
  notificationList(data: any) {
    return this.api.postApiCallAuth(
      NetworkService.getNotificationsList(),
      data
    );
  }

  getprofile() {
    return this.api.postApiCallAuth(NetworkService.getProfile(), {
      version: 0.2,
    });
  }

  updateProfile(data) {
    return this.api.postApiCallAuth(NetworkService.profileUpdate(), data);
  }

  //upcoming List
  upcomingList(data: any) {
    return this.api.postApiCallAuth(NetworkService.upcommingList(), data);
  }

  getDashboard() {
    return this.api.getApiCallAuth(NetworkService.dashboard());
  }

  getSupplierList(type: any) {
    return this.api.optionApiCallAuth(NetworkService.getSupplierOptions(type));
  }

  // notification

  getNotification() {
    return this.api.getApiCallAuth(NetworkService.notificationList());
  }

  openNotification(data: any) {
    return this.api.getApiCallAuth(NetworkService.openNotification(data));
  }

  // monster Foods

  becomeAFranchise(){
    return this.api.getApiCallAuth(NetworkService.becomeAFranchies());
  }


  contactUs(){
    return this.api.getApiCallAuth(NetworkService.contactUs());
  }
}
