import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LoaderComponent } from "./pages/staticPages/loader/loader.component";
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public loader = LoaderComponent;
  auth2: any;
  @ViewChild("loginRef", { static: true }) loginElement!: ElementRef;
  ngOnInit() {
    this.setToken();
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }

  setToken() {
    // sessionStorage.setItem("accessToken", "test");
  }
}
