import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveMenuService {

  constructor() { }

  private _activePrimaryMenu = '';
  public activePrimaryMenu: BehaviorSubject<string> = new BehaviorSubject<string>(this._activePrimaryMenu);
  
  private _activeSecondaryMenu = '';
  public activeSecondryMenu: BehaviorSubject<string> = new BehaviorSubject<string>(this._activeSecondaryMenu);
  
}
