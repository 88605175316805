<div class="empty-scn">
  <div>
    <div class="d-flex flex-column empty justify-content-center">
      <img
        class="empty-img"
        src="../../../../assets/images/loader_g.gif"
        alt=""
      />
      <!-- <h5 align="center" class="mt-2">{{ title | titlecase }}</h5> -->
    </div>
  </div>
</div>
